/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dataService } from '../../services/dataService';
import Userfilter from '../UserManagement/Users/userfilter';
import Lottie from 'lottie-react';
import Debounce from '../../CommonComponent/debounce';
import PageNavigation from '../../CommonComponent/pageNavigation';
import GlobalContext from '../../CommonComponent/context';
import { SearchBar } from '../../CommonComponent/Searchbar';
import ItemShimmer from '../../CommonComponent/itemShimmer';
import EmptyScreen from '../UserManagement/Users/emptyscreen';
import blockImage from '../../CommonComponent/search_not_found.json'
import ActionModal from '../../CommonComponent/actionModal';
import { handleCurrentPage, handlePagination } from '../../Redux/paginationSlice';
import { PayrollsTableBody } from './PayrollTableBody';
import AnimatedPage from '../../CommonComponent/AnimatedPage';
import Payrollfilter from './payrollfilter'

export default function PayrollsListing() {
    const { setToastmessage, setToastErrorMessage } = useContext(GlobalContext)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams({ limit: 10, sort: 'true', key: 'payroll_name', order: 'asc',payroll_status:'active'  });
    const [searchValue, setSearchValue] = useState(searchParams.get('keyword') !== null ?
        decodeURIComponent(searchParams.get('keyword')) : '')
    const [sort, setSort] = useState(`${searchParams.get('key') !== null ? decodeURIComponent(searchParams.get('key')) : ''}+${searchParams.get('order') !== null ? decodeURIComponent(searchParams.get('order')) : ''}`)
    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState({})
    const [nextPage, setNextPage] = useState(true)
    const [prevPageExist, setPrevPage] = useState(false)
    const currentPage = useSelector((state) => state.paginationConfig.currentPage);
    const [actionModal, setActionModal] = useState('')
    const dispatch = useDispatch();
    const paginationData = useSelector((state) => state.paginationConfig.paginationData);
    const [selectedUser, setSelectedUser] = useState({})
    const [spinnerLoader, setSpinnerLoader] = useState(false)
    const [searchExist, setSearchExist] = useState(true)
    const [noRecord, setNoRecord] = useState(false)
    const [isEdit, setIsEdit] = useState({})

    // filter related state
    const [filterData, setFilterData] = useState({
        country: searchParams.get('country') !== null ? decodeURIComponent(searchParams.get('country')) : '',
        payroll_status: searchParams.get('payroll_status') !== null ? decodeURIComponent(searchParams.get('payroll_status')) : '',
        payroll_type: searchParams.get('payroll_type') !== null ? decodeURIComponent(searchParams.get('payroll_type')) : ''
    });
    const [filterError, setFilterError] = useState({
        country:"",
        payroll_status: "",
        payroll_type: ""
    });

    const [isFilterApplied, setIsFilterApplied] = useState(searchParams.get('country') || searchParams.get('payroll_type') || searchParams.get('payroll_status')  !== null ? true : false)
    const [isFilterOpen, setIsfilteropen] = useState(false)
    const filterRef = useRef(null);
    const [addLoader, setAddLoader] = useState(false)
    const [selectedFilterParameter, setSelectedFilterParameter] = useState('country')
    const [countryList, setCountryList] = useState([])



    const getCountry = async () => {
        try {
            const data = await dataService.CountriesListing();
            if (data?.error === false && data?.data?.success_status === true) {
                setCountryList(data.data.data);
            } else {
                console.error('Error in API response or success_status is false');
            }
        } catch (error) {
            // Handle any errors during the API call
            console.error('Error fetching country data:', error);
        }
    };

    let extractedData = countryList.map(({ country_name, flag, country_id }) => ({ country_name, flag, country_id }));
    const sortedData = extractedData.sort((a, b) => a.country_name.localeCompare(b.country_name));

    useEffect(() => {
        getCountry()
    }, [])
    

    const roleMapping = {
        'Administrator': 'admin',
        'User': 'user',
        // Add other role mappings as needed
    };

    const transformRoles = (roles) => {
        return roles.split(',').map(role => roleMapping[role.trim()] || role.trim()).join(',');
    };

    /* The above code is a React function component that defines a function `getPayrollList` using
    the `useCallback` hook. This function is an asynchronous function that fetches user group data
    based on certain search parameters. */
    const getPayrollList = useCallback(async () => {
        setLoading(true);
        let endpoint = `?limit=10`;

        if (searchParams.get('keyword') !== null) {
            endpoint += `&search=${searchParams.get('keyword')}`;
        }
        if (searchParams.get('last_element') !== null) {
            endpoint += `&last_element=${searchParams.get('last_element')}`;
        }
        if (searchParams.get('key') !== null) {
            endpoint += `&sort=${encodeURIComponent(sort)}`;
        }
        if (searchParams.get('country') !== null) {
            endpoint += `&country_id=${searchParams.get('country')}`;
        }
        if (searchParams.get('payroll_status') !== null) {
            endpoint += `&payroll_status=${searchParams.get('payroll_status')}`;
        }
        if (searchParams.get('payroll_type') !== null) {
            endpoint += `&payroll_type=${searchParams.get('payroll_type')}`;
        }

        const data = await dataService.ListPayrolls(endpoint);
        if (data.data.success_status === true) {
            if (data.data.status == "NO_DATA") {
                setNoRecord(true);
                setUserList(data.data.data);
            } else {
                setNoRecord(false);
                setUserList(data.data.data);
            }
            if (data.data.status == 'NO_MATCH') {
                setSearchExist(false);
                setUserList(data.data.data);
            } else {
                setSearchExist(true);
                setUserList(data.data.data);
            }
            if (searchValue == '' && data.data.data.length == 0) {
                setNextPage(false);
            } else {
                setUserList(data.data.data);
                setNextPage(true);
            }
            if (data.data.last_element === undefined || data.data.last_element === null) {
                setNextPage(false);
            } else {
                setNextPage(true);
                dispatch(handlePagination({
                    ...paginationData,
                    [currentPage + 1]: data.data.last_element,
                }));
            }
            setTimeout(() => {
                setLoading(false);
            }, 300);
        } else {

            if (data.data.message.sort !== undefined && data.data.message?.sort[0]?.includes('Invalid sort value') ||
                data.data.message.includes('Please provide valid input') || data.data.message.includes("Invalid last_element token") || data.data.message.includes("Status must be one of 'active', 'inactive', 'creating', or valid combinations.")  ) {
                navigate('/page-not-found');
            }
        }
    }, [searchParams]);

    console.log(Object.fromEntries(searchParams), 'searh')

    useEffect(() => {
        getPayrollList()
    }, [getPayrollList])

    /**
 * The handleSort function in JavaScript React is used to update sorting parameters based on the key
 * provided.
 */
    const handleSort = (e, key) => {
        setNextPage(true)
        setPrevPage(false)
        dispatch(handleCurrentPage(1))
        let params = Object.fromEntries(searchParams);
        delete params.last_element;
        if (searchParams.get('sort') === false || searchParams.get('sort') === null) {
            params['sort'] = 'true'
            params['key'] = `${key}`
            params['order'] = `asc`
            setSort(key + '+asc')
        } else {
            if (searchParams.get('key').includes(key)) {
                if (searchParams.get('order') === 'asc') {
                    params['order'] = `desc`
                    setSort(key + '+desc')
                } else {
                    params['order'] = `asc`
                    setSort(key + '+asc')
                }
            }
            else {
                params['key'] = key
                params['order'] = `asc`
                setSort(key + '+asc')
            }
        }
        setSearchParams({ ...params })
    }

    /**
     * The function `handleChange` handles input changes by debouncing the search function
     * `handleSearch` with a delay of 300 milliseconds.
     */
    const handleChange = (e, value) => {
        handleSearch(e, "keyword", value);
    };
    const changeHandler = (e) => {
        setSearchValue(e.target.value);
    };
    const optimizedFn = useCallback(
        Debounce((e, value) => handleChange(e, value)),
        [searchParams]
    );
    const handleSearch = (e, key, value) => {
        e.preventDefault();
        setNextPage(true)
        setPrevPage(false)
        dispatch(handleCurrentPage(1))
        let params = Object.fromEntries(searchParams);
        delete params.last_element;
        if (key === 'keyword') {
            params[key] = encodeURIComponent(value);
        } else {
            params[key] = value
        }
        if (params['keyword'] === '') delete params.keyword
        setSearchParams({ ...params })

    }

    /**
     * The above functions handle pagination by updating the current page and search parameters for
     * fetching data in a React application.
     */
    const handlePrevPage = async () => {
        setNextPage(true)
        let params = Object.fromEntries(searchParams);
        if (currentPage === 2) {
            setPrevPage(false)
            dispatch(handleCurrentPage(currentPage - 1))
            delete params.last_element
            setSearchParams({ ...params })
        }
        else {
            dispatch(handleCurrentPage(currentPage - 1))
            setPrevPage(true)
            const lastKey = paginationData[currentPage - 1]
            if (lastKey) {
                params['last_element'] = lastKey
                setSearchParams({ ...params })
            }
        }
    }
    /**
    * The function `handleNextPage` increments the current page number, sets a flag for the previous
    * page if the current page is greater than or equal to 1, and updates search parameters with
    * pagination data for the next page.
    */
    const handleNextPage = async () => {
        let params = Object.fromEntries(searchParams);
        if (currentPage >= 1) {
            setPrevPage(true)
        }
        dispatch(handleCurrentPage(currentPage + 1))
        params['last_element'] = paginationData[currentPage + 1]
        setSearchParams({ ...params })
    }

    /**
    * The function `handleStatusModal` sets the selected user and opens a status modal.
    */
    const handleActionModal = async (user, selectedModelType) => {
        setSelectedUser(user)
        setActionModal(selectedModelType)
    }
    /**
    * The function `handlestatus` activate or deactivate a user group and displays a success message or an error
    * message accordingly.
    */
    const handleStatus = async () => {
        let body = {
            status: selectedUser.user_status === 'active' ? 'inactive' : 'active'
        }
        if (selectedUser.user_role === "super-admin" || selectedUser.current_user) {
            setActionModal('')
            return
        }
        setSpinnerLoader(true)
        const response = await dataService.ActivateDeactivateUser(selectedUser.user_id, body)
        if (response.error === false) {
            setActionModal('')
            setSpinnerLoader(false)
            setToastmessage(`User ${selectedUser.user_status === 'active' ? "deactivated" : "activated"} successfully`)
            getPayrollList()
        } else {
            setActionModal('')
            setSpinnerLoader(false)
            setToastErrorMessage('Something went wrong')
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setIsfilteropen(false);
                if (searchParams.get('country') === null) {
                    setFilterData((prevState) => ({
                        ...prevState,
                        country: "",
                    }));
                } else {
                    setFilterData((prevState) => ({
                        ...prevState,
                        country: searchParams.get('country'),
                    }));

                } if (searchParams.get('payroll_status') === null) {
                    setFilterData((prevState) => ({
                        ...prevState,
                        payroll_status: "",
                    }));
                } else {
                    setFilterData((prevState) => ({
                        ...prevState,
                        payroll_status: searchParams.get('payroll_status'),
                    }));
                }

                if (searchParams.get('payroll_type') === null) {
                    setFilterData((prevState) => ({
                        ...prevState,
                        payroll_type: "",
                    }));
                } else {
                    setFilterData((prevState) => ({
                        ...prevState,
                        payroll_type: searchParams.get('payroll_type'),
                    }));
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchParams]);


    const handleFilter = () => {
        setNextPage(true);
        setPrevPage(false);
        dispatch(handleCurrentPage(1));
        let params = Object.fromEntries(searchParams);
        delete params.last_element;
        params['page'] = 1;
        // params['filter'] = 'true';
        setSearchParams({ ...params, page: 1 });

        // Check if filter data is empty
        if (filterData.country === '' && filterData.payroll_status === '' && filterData.payroll_type === '') {
            // delete params.filter;
            setIsFilterApplied(false);
        } else {
            // params['filter'] = 'true';
            setIsFilterApplied(true);
        }

        if (filterData.country !== '') {
            params['country'] = filterData.country;
            setIsFilterApplied(true);
        } else {
            delete params.country;
        }

        if (filterData.payroll_status !== '') {
            params['payroll_status'] = filterData.payroll_status;
            setIsFilterApplied(true);
        } else {
            delete params.payroll_status;
        }

        if (filterData.payroll_type !== '') {
            params['payroll_type'] = filterData.payroll_type;
            setIsFilterApplied(true);
        } else {
            delete params.payroll_type;
        }

        setIsfilteropen(false);
        setSearchParams({ ...params });
    };


    const clearFilters = () => {
        let params = Object.fromEntries(searchParams);
        setFilterData({
            country:"",
            payroll_status: "",
            payroll_type: ""
        });
        delete params.country;
        delete params.payroll_status;
        delete params.payroll_type;
        setIsfilteropen(false);
        setSearchParams({ ...params });
        setIsFilterApplied(false);
        delete params.payroll_status;

        
    }

    const handleResetPassword = async () => {
        setSpinnerLoader(true)
        if (selectedUser.user_role === "super-admin" || selectedUser.current_user) {
            setActionModal('')
            setSpinnerLoader(false)
            return
        }
        const response = await dataService.patchAPI(`user-management/reset-password/${selectedUser.user_id}`);
        if (response.error === false) {
            setSpinnerLoader(false)
            setActionModal('')
            setToastmessage('Password reset successfully')
        } else {
            setSpinnerLoader(false)
            setActionModal('')
            setToastErrorMessage('Something went wrong!')
        }
    }

    const getModalText = () => {
        if(selectedUser.user_role === 'super-admin') {
            switch (actionModal) {
            case 'password':
                return 'Super admin password cannot be reset.';
            case 'edit':
                return 'Super admin cannot be updated.';
            case 'status':
                if(selectedUser.user_status === "active") {
                    return 'Super Admin cannot be deactivated.'
                } else {
                    return 'Super Admin cannot be activated.'
                }
            default:
                break;
            }
        } else if (selectedUser.current_user) {
            switch (actionModal) {
            case 'password':
                return 'You cannot reset your own password.';
            case 'edit':
                return 'You cannot update your own account.';
            case 'status':
                if(selectedUser.user_status === "active") {
                    return 'You cannot deactivate your own account.'
                } else {
                    return 'You cannot activate your own account.'
                }
            default:
                break;
            }
        } else {
            let text = '';
            switch (actionModal) {
            case 'password':
                text = 'reset the password of'
                break;
            case 'status':
                if(selectedUser.user_status === "inactive") {
                    text = 'activate'
                } else {
                    text = 'deactivate'
                }
            default:
                break;
            }
            return <>
                {`Are you sure you want to ${text} the user`}
                <strong> {selectedUser && selectedUser.first_name + " " + selectedUser.last_name}</strong>?
            </>
        }
    }

    const getCountryNameById = (id) => {
        const country = countryList.find((country) => country.country_id == id);
        return country ? country.country_name : '';
    };

    return (
        <>
            <AnimatedPage>
                <div className='px-6 pt-[68px] rounded-tl-lg bg-white h-[calc(100vh-51px)]'>
                    <h1 className="text-[#2F2E38] text-[32px] font-bold pb-[24px]">Payrolls</h1>
                    {!noRecord &&
                    <div className='flex justify-between items-end'>
                        <SearchBar
                            searchValue={searchValue}
                            optimizedFn={optimizedFn}
                            changeHandler={changeHandler}
                            placeholder={'Search by payroll name'}
                        />

                        <div className='flex items-center'>
                            <div className="relative">
                                <Payrollfilter
                                    filterRef={filterRef}
                                    setIsfilteropen={setIsfilteropen}
                                    isFilterOpen={isFilterOpen}
                                    filterError={filterError}
                                    setFilterError={setFilterError}
                                    isFilterApplied={isFilterApplied}
                                    clearFilters={clearFilters}
                                    handleFilter={handleFilter}
                                    selectedFilterParameter={selectedFilterParameter}
                                    setSelectedFilterParameter={setSelectedFilterParameter}
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                    sortedData={sortedData}
                                    getCountryNameById={getCountryNameById}
                                />
                            </div>
                            <button
                                disabled={addLoader}
                                className='flex justify-center items-center py-2.5 px-5 w-[110px] h-10
                                        rounded-lg bg-[#29275F] text-base text-[#FCFBFE] font-bold hover:bg-[#4B42A3] disabled:bg-[#CCC9E9]' data-testid="add-btn"
                                onClick={() => navigate('/create-payroll')}
                            >
                                <img src='/Images/plus.svg' alt='icon' className='mr-[9px]' loading='lazy' />
                                Add
                            </button>
                        </div>

                    </div>
                    }
                    <div className={`flex table-content mt-[25px] overflow-auto items-start h-[calc(100vh-325px)] customScrollbar`}>
                        <table aria-label="Outside Table" className="w-full table-auto overflow-auto customScrollbar pb-2">
                            {!noRecord && !loading &&
                            <thead>
                                <tr className="bg-[#F1F1F4] h-[50px] w-full sticky top-0 left-0 z-10">
                                    <th className="px-6 py-3 min-w-[250px] sticky left-0 bg-[#F1F1F4] z-[1]">
                                        <span className="flex items-center text-base leading-5 text-[#2F2E38] font-semibold">
                                            Payroll name
                                            <button type='button' className="pl-[14px]" data-testid="payroll_name"
                                                onClick={(e) => { handleSort(e, 'payroll_name') }}
                                            >
                                                {sort.includes('payroll_name+asc') ?
                                                    <img decoding="async" loading="lazy" id="sort"
                                                        src="/Images/sort-icon.svg" alt="icon" /> :
                                                    <img decoding="async" loading="lazy" id="sort"
                                                        src="/Images/sort-icon.svg" alt="icon" className='rotate-180' />}
                                            </button>
                                        </span>
                                    </th>
                                    <th className="px-6 py-3 min-w-[180px]">
                                        <span className="flex items-center text-base leading-5 text-[#2F2E38] font-semibold">
                                            Payroll type
                                        </span>
                                    </th>
                                    <th className="px-6 py-3 min-w-[300px]">
                                        <span className="flex items-center text-base leading-5 text-[#2F2E38] 
                                    font-semibold">
                                            Number of periods
                                        </span>
                                    </th>
                                    <th className="px-6 py-3 min-w-[180px]">
                                        <span className="flex items-center text-base leading-5 text-[#2F2E38] font-semibold">
                                            Current period
                                        </span>
                                    </th>
                                    <th className="px-6 py-3 min-w-[180px]">
                                        <span className="flex items-center text-base leading-5 text-[#2F2E38] 
                                    font-semibold">
                                            Country
                                        </span>
                                    </th>
                                    <th className="px-6 py-3 min-w-[180px]">
                                        <span className="flex items-center text-base leading-5 text-[#2F2E38] font-semibold">
                                            Status
                                        </span>
                                    </th>
                                    <th className="px-6 py-3 min-w-[200px] last-header sticky right-0 bg-[#F1F1F4] z-[1]">
                                        <span className="flex items-center justify-center text-base leading-5 
                                             text-[#2F2E38] font-semibold">Actions
                                        </span>
                                    </th>
                                </tr>
                            </thead>}
                            {loading ?
                                !noRecord && <tbody>
                                    {Array(11).fill().map((_, index) => (
                                        <tr key={index}>
                                            {Array(6).fill(null).map((_, index) => (<td key={index}><ItemShimmer /></td>))}
                                        </tr>
                                    ))}
                                </tbody> :
                                <>
                                    <tbody>
                                        <td colSpan={7}>
                                            {loading ?
                                                <EmptyScreen /> :
                                                searchExist === false &&
                                                <div className="error-message">
                                                    <Lottie
                                                        animationData={blockImage}
                                                        loop={true}
                                                        className="w-[240px] mx-auto mt-8"
                                                    />
                                                    <p data-testid="empty_screen" className='text-[18px] font-semibold text-[#2F2E38] text-center pb-5 pt-12'>
                                                        We could not find any {' '}

                                                        {isFilterApplied && searchValue !== '' ?
                                                            'matches or results based your search or chosen filters' :
                                                            isFilterApplied ? 'results based on your chosen filters' : 'matches for your search'}.</p>
                                                </div>
                                            }
                                        </td>
                                    </tbody>
                                    {!noRecord && searchExist &&
                                    <PayrollsTableBody
                                        data={userList}
                                        handleActionModal={handleActionModal}

                                    />
                                    }
                                </>
                            }
                        </table>
                    </div>
                    {(!loading && ((nextPage || prevPageExist || currentPage > 1) && searchExist)) &&
                    <PageNavigation
                        handlePrevPage={handlePrevPage}
                        handleNextPage={handleNextPage}
                        prevPageExist={prevPageExist}
                        nextPage={nextPage}
                        currentPage={currentPage}
                        loading={loading}
                    />
                    }
                </div>
            </AnimatedPage>
            {actionModal !== '' &&
                <ActionModal
                    isOpen={actionModal !== ''}
                    setIsOpen={setActionModal}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    mainHeading={actionModal === 'password' ? 'Reset Password' : actionModal === 'edit' ? 'Update User' : `${selectedUser.user_status == "inactive" ? "Activate User" : "Deactivate User"}`}
                    message={getModalText()}
                    ctaButton={`${(selectedUser.user_role == "super-admin" || selectedUser.current_user) ? "Go Back" :actionModal === 'password' ? 'Reset' : selectedUser.user_status == "inactive" ? "Activate" : "Deactivate"}`}
                    cancelButton={(selectedUser.user_role === "super-admin" || selectedUser.current_user) ? false : 'Cancel'}
                    handleClickFunction={actionModal === 'password' ? handleResetPassword : handleStatus}
                    loader={spinnerLoader}
                    setLoader={setSpinnerLoader}
                />
            }
        </>
    );
}

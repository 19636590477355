import React from 'react'
import { motion } from 'framer-motion'

export default function CardHeder({children, headerName}) {
    let animationProps = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: 0.5 } },
    };
    return (
        <div className='bg-white rounded-tl-lg h-[calc(100vh-51px)]'>
            <motion.div {...animationProps} className="px-6 pt-[72px]">
                <h1 className="text-[#2F2E38] text-[32px] font-bold">{headerName}</h1>
                {children}
            </motion.div>
        </div>
    )
}

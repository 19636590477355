import React, { useEffect, useState } from 'react'
import Toast from './CommonComponent/toastMeasseage';
import NavigationRoutes from './Routes'
import GlobalContext from './CommonComponent/context';
import { dataService } from './services/dataService';
import configureAmplify from './components/AmplyfyConfig';


function App(props) {
    const [awsConfig, setAwsConfig] = useState(false);
    const navigationPath = NavigationRoutes(props, awsConfig)
    const [Toastmessage, setToastmessage] = useState('');
    const [ToastErrorMessage, setToastErrorMessage] = useState('')
    const [toastWarningMessage, setToastWarningMessage] = useState('')
    const [token, setToken] = useState('')
    const [countryList, setCountryList] = useState([])


    const getCountry = async () => {
        const data = await dataService.CountriesListing();
        setCountryList(data.data.data.Items)
    }


    useEffect(() => {
        configureAmplify(setAwsConfig);
    }, [])

    /* The `useEffect` hook in this code is used to perform side effects in a functional component. In
        this case, it is used to check if there is a token parameter in the URL query string. */
    useEffect(() => {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const token = params.get('token');
        setToken(params.get('token'))

        // if (token===null || token==undefined) {
        //     navigate('/')
        // }
        // getCountry()
    }, [])

    return (
        <GlobalContext.Provider
            value={{ setToastmessage, Toastmessage, setToastErrorMessage, toastWarningMessage , 
                setToastWarningMessage ,awsConfig, countryList }}>
            <div>
                {Toastmessage !== '' && <Toast
                    message={Toastmessage}
                    setToastmessage={setToastmessage}
                    type="success"
                />}
                {ToastErrorMessage !== '' && <Toast
                    message={ToastErrorMessage}
                    setToastmessage={setToastErrorMessage}
                    type="error"
                />}
                {toastWarningMessage !== '' && (
                    <Toast
                        message={toastWarningMessage}
                        setToastmessage={setToastWarningMessage}
                        type="warning"
                    />
                )}
                {navigationPath}
            </div>
        </GlobalContext.Provider>
    )
}

export default App

/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion'
import { useLocation, useNavigate } from 'react-router-dom';
import LogoutModal from './logoutModal';
import HoveringTooltip from '../../CommonComponent/HoveringTooltip';
import { handleCurrentPage, handlePagination } from '../../Redux/paginationSlice';

export default function Topbar() {
    const userData = useSelector((state) => state.auth)
    const isAnimating = useSelector((state) => state?.commonData?.isAnimating)
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const [dropdwonOpen, setDropdownOpen] = useState(false)
    const dropdown = useRef(null);
    const path = useLocation()
    const dispatch = useDispatch()
    let paginationData = {}

    let animationProps;
    if (isAnimating) {
        animationProps = {
            initial: { opacity: 0, scale: 0.6 },
            animate: { opacity: 1, scale: 1 },
            transition: {
                duration: 0.3,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
            }
        };
    }
    else {
        animationProps = {
            initial: { opacity: 1, scale: 1 },
        }
    }
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdown.current && !dropdown.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdown]);
    return (
        <>
            <nav className='h-[50px] bg-[#29275F]'>
                <div className='flex justify-end items-center h-full'>
                    <li
                        onClick={() => {
                            if (!window.location.pathname.includes('/payrolls')) {
                                dispatch(handlePagination(paginationData));
                                dispatch(handleCurrentPage(1));
                                navigate('/payrolls');
                            }
                        }}
                        data-tooltip-id='Payrolls-tooltip' 
                        className='custom-li side-menu hover:bg-[#4B42A3] rounded-full w-10 h-10 mr-3 flex justify-center items-center'>
                        <motion.img {...animationProps} src='/Images/calendar-dollar-monthly-icon.svg' alt='icon'
                            loading='lazy' decoding="async"
                            className='cursor-pointer' />
                        <motion.img {...animationProps} src='/Images/calendar-dollar-monthly-icon.svg' alt='icon'
                            loading='lazy' decoding="async"
                            className='cursor-pointer' />
                    </li>
                    <HoveringTooltip place={"bottom"} Name={'Payrolls'} id={'Payrolls-tooltip'} />
                    
                    <li className='custom-li side-menu hover:bg-[#4B42A3] rounded-full w-10 h-10 mr-3 
                flex justify-center items-center' data-tooltip-id='Settings-tooltip'>
                        <motion.img {...animationProps} src='/Images/global-settings.svg' alt='icon' loading='lazy'
                            decoding="async"
                            className='cursor-pointer' />
                        <motion.img {...animationProps} src='/Images/global-settings.svg' alt='icon' loading='lazy'
                            decoding="async"
                            className='cursor-pointer' />
                    </li>
                    <HoveringTooltip place={"bottom"} Name={'Settings'} id={'Settings-tooltip'} />
                    <li className='custom-li side-menu hover:bg-[#4B42A3] rounded-full w-10 h-10 mr-3 
                flex justify-center items-center' data-tooltip-id='Notifications-tooltip'>
                        <motion.img {...animationProps} src='/Images/global-notification.svg' alt='icon'
                            loading='lazy' decoding="async"
                            className='cursor-pointer' />
                        <motion.img {...animationProps} src='/Images/global-notification.svg' alt='icon'
                            loading='lazy' decoding="async"
                            className='cursor-pointer' />
                    </li>
                    <HoveringTooltip place={"bottom"} Name={'Notifications'} id={'Notifications-tooltip'} />
                    <div className='mr-6' ref={dropdown}>
                        <div className='text-[#29275F] text-[10px] font-normal bg-[#FFFFFF] w-6 h-6 
                    rounded-full' onClick={() => setDropdownOpen(!dropdwonOpen)} data-testid="profile-btn">
                            <button type='button' className='flex items-center justify-center w-full h-full'>
                                {userData?.user?.name[0]?.toUpperCase() || ""}{''}
                                {userData?.user?.middle_name ? userData?.user?.middle_name?.[0]?.toUpperCase() : ''}
                            </button>
                        </div>
                        {dropdwonOpen && <div
                            className={`p-0 bg-white z-[99] outline-0 border border-[#CCC9E9] mt-2.5 
                            absolute top-[46px] right-6 left-auto transform-none rounded shadow-s2 
                            w-[275px] transition-all ${dropdwonOpen ? 'opacity-1' : 'opacity-0'}`}>
                            <div className='border-b border-[#CCC9E9] py-3 px-6 outline-0'>
                                <div className='flex items-center'>
                                    <div className='w-10 h-10 rounded-full px-1.5 pt-[1px] text-[#EEEDF8] text-base font-normal 
                        bg-[#29275F] flex justify-center items-center mr-4'>
                                        {userData?.user?.name[0].toUpperCase()}{''}
                                        {userData?.user?.middle_name && userData?.user?.middle_name?.[0]?.toUpperCase() || ''}
                                    </div>
                                    <div className='w-[180px]'>
                                        <p className='text-[#2F2E38] text-base font-normal break-all'>
                                            {userData?.user?.name[0].toUpperCase() + userData.user.name.slice(1)}{' '}
                                            {userData?.user?.middle_name &&
                                                userData?.user?.middle_name[0].toUpperCase() + userData.user.middle_name.slice(1)}
                                        </p>
                                        <p className='text-[#918FA3] text-sm font-normal break-all'>
                                            {userData?.user?.email}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='py-3 outline-0'>
                                <div
                                    data-test-id="profile-button"
                                    className={`text-[#2F2E38] text-sm font-normal flex items-center py-2 px-6 cursor-pointer 
                            ${path.pathname == '/profile' ? 'hover:bg-[#CCC9E9]' : 'hover:bg-[#EEEDF8]'}`}
                                    onClick={() => navigate('/profile')}>
                                    <motion.img {...animationProps} src='/Images/user-check.svg' alt='icon' loading='lazy'
                                        decoding="async"
                                        className='mr-3' />
                                    Profile
                                </div>
                                <div
                                    className={`text-[#2F2E38] text-sm font-normal flex items-center py-2 px-6 cursor-pointer 
                            ${path.pathname == '/change-password' ? 'hover:bg-[#CCC9E9]' : 'hover:bg-[#EEEDF8]'}`}
                                    onClick={() => navigate('/change-password')}>
                                    <motion.img {...animationProps} src='/Images/change-password.svg' alt='icon'
                                        loading='lazy' decoding="async"
                                        className='mr-3' />
                                    Change Password
                                </div>
                            </div>
                            <div className='outline-0'>
                                <div
                                    className='text-[#2F2E38] text-sm font-normal flex items-center py-3 px-6 cursor-pointer 
                            hover:bg-[#EEEDF8] border-t border-[#CCC9E9]'
                                    onClick={() => { setIsOpen(true); setDropdownOpen(false) }}
                                    data-testid="logout-btn">
                                    <motion.img {...animationProps} src='/Images/log-out.svg' alt='icon'
                                        loading='lazy' decoding="async"
                                        className='mr-3' />
                                    Logout
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </nav>
            <LogoutModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    );
}

/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
import React, { useState } from 'react';

const NestedCheckboxes = ({ items, setFormInput, formInput }) => {
    /**
     * The function `handleCheckboxChange` updates the `payroll` array in the form input state based on
     * the checked status of a checkbox item.
     */
    const handleCheckboxChange = (checked, itemId) => {
        setFormInput(prevState => {
            let updatedPayroll;
            if (checked) {
                updatedPayroll = [...new Set([...prevState.payroll, itemId])]; // Add itemId if checked and remove duplicates
            } else {
                updatedPayroll = prevState.payroll.filter(item => item !== itemId); // Remove itemId if unchecked
            }
            return {
                ...prevState,
                payroll: updatedPayroll,
            };
        });
    };    

    /**
     * The function `handleParentCheckboxChange` updates the `payroll` state based on the checked
     * status and items array.
     */
    const handleParentCheckboxChange = (checked) => {
        if (checked && items.length > 0) {
            const selectedItems = items.map(item => item.id);
            setFormInput(prevState => ({
                ...prevState,
                payroll: [...prevState.payroll, ...selectedItems],
            }));
        } else if (!checked && items.length > 0) {
            const unselectedItems = items.map(item => item.id);
            setFormInput(prevState => ({
                ...prevState,
                payroll: prevState.payroll.filter(item => !unselectedItems.includes(item)),
            }));
        }
    };

    return (
        <div>
            <div className='checkbox relative'>
                <label htmlFor="parentCheckbox" className="pl-[33px] cursor-pointer text-[#2F2E38] text-base font-normal flex items-center p-2 w-fit">
                    Select All
                    <input
                        className="checkbox-input custom-checkbox-input"
                        type="checkbox"
                        id="parentCheckbox"
                        checked={formInput?.payroll?.length === items?.length && items.every(item => formInput.payroll.includes(item.id))}
                        onChange={(e) => handleParentCheckboxChange(e.target.checked)}
                    />
                </label>
            </div>
            <div className="grid grid-cols-3">
                {items.length > 0 && items.map(item => (
                    <div key={item.id} className='checkbox relative'>
                        <label htmlFor={`checkbox-${item.id}`} className="pl-[33px] cursor-pointer text-[#2F2E38] text-base font-normal flex items-center p-2 w-fit">
                            {item.label}
                            <input
                                data-testid={`${item.label}`}
                                className="checkbox-input custom-checkbox-input"
                                type="checkbox"
                                id={`checkbox-${item.id}`}
                                checked={formInput?.payroll?.includes(item.id)}
                                onChange={(e) => handleCheckboxChange(e.target.checked, item.id)}
                            />
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NestedCheckboxes;
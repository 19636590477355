/* eslint-disable max-len */
import React, { useState } from "react";

const InputWithLabel = ({
    label,
    formInput,
    handleChange,
    handlePasswordFocus,
    required,
    errorState,
    showPassword,
    maxLength,
    Lastname,
    ...rest
}) => {
    const [focused, setFocused] = useState(false);

    return (
        <div className={`${label == "Password" ? "mb-2.5" : "mb-[36px]"}`}>
            <div className="relative">
                <label
                    className={`absolute transition-all left-2 text-[14px] font-normal bg-white ${errorState[rest.id] !== "" ? "text-[#EA3829]" : "text-[#2F2E38]"
                    } duration-300 ${focused || formInput[rest.id] ? "top-[-8px] text-xs px-[4px]" : "top-[50%] translate-y-[-50%] text-sm mx-[4px]"
                    }`}
                    htmlFor={rest.id}
                >
                    {label}
                    {required && <span className={`${errorState[rest.id] !== "" ? "text-[#EA3829]" : "text-[#2F2E38]"}`}>*</span>}
                </label>
                <input
                    {...rest}
                    maxLength={maxLength}
                    data-testid={rest.id}
                    value={formInput[rest.id] || ""}
                    onChange={(e) => handleChange(e)}
                    onFocus={(e) => { setFocused(true); if(handlePasswordFocus) {handlePasswordFocus(e)} }}
                    onBlur={() => { setFocused(false) }}
                    className={`text-[#2F2E38] border h-[50px] rounded-[4px] ${showPassword ? 'text-[14px] leading-5 ' : 'text-[24px] leading-[14px]'} font-normal w-full py-[14px] px-[12px] outline-0 focus-visible:outline-0 ${label == "Password" && 'pr-[70px]'} ${errorState[rest.id] !== ""
                        ? "border-[#EA3829]"
                        : focused || formInput[rest.id]
                            ? "border-[#29275F]"
                            : "border-[#ACABBA]"
                    }`}
                />
            </div>
            <p className="text-[#EA3829] text-xs font-normal pt-[2px] pl-3">
                {errorState[rest.id]}
            </p>
        </div>
    );
};

export default InputWithLabel;

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { useLocation, useNavigate } from 'react-router';
import LogoutModal from './logoutModal';
import { motion, AnimatePresence } from 'framer-motion'
import { handleSidebarAnimation } from '../../Redux/commonSlice';
import { handleCurrentPage, handlePagination } from '../../Redux/paginationSlice';


export default function Sidebar() {
    const navigate = useNavigate()
    const path = useLocation()
    const userData = useSelector((state) => state.auth)
    const [isRefreshed, setIsRefreshed] = useState(true);
    const isAnimating = useSelector((state) => state?.commonData?.isAnimating)
    const dispatch = useDispatch()
    let paginationData = {}

    useEffect(() => {
        setIsRefreshed(false);
    }, []);

    let animationProps;
    if (isAnimating) {
        animationProps = {
            initial: { opacity: 0, scale: 0.6 },
            animate: { opacity: 1, scale: 1 },
            transition: {
                duration: 0.3,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
            }
        };
    }
    else {
        animationProps = {
            initial: { opacity: 1, scale: 1 },
        }
    }
    return (
        <AnimatePresence>
            <div className='min-w-[256px] bg-[#29275F] pt-7 px-[17px] pb-8 sidebar-container'>
                <nav className='flex flex-col justify-between h-full'>
                    <ul>
                        <li className='pb-6'>
                            <img src='/Images/logo-withName.svg' alt='icon' loading='lazy' />
                        </li>
                        <li className='flex items-center px-2 py-1.5 bg-[#29275F] border border-[#4B42A3] rounded justify-between mb-3'>
                            <div className='flex flex-col'>
                                <div className='flex flex-row items-center'>
                                    <p className='text-sm text-[#FFFFFF] font-normal'>Payroll: </p>
                                    <p className='text-[#FFFFFF] text-xs font-semibold pl-[4px]'>PayPro Sloutions</p>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <p className='text-sm text-[#FFFFFF] font-normal'>Period: </p>
                                    <p className='text-[#FFFFFF] text-xs font-semibold pl-[4px]'>2021 / 12</p>

                                </div>
                            </div>

                            <div>
                                <img src='/Images/chevron-down-white.svg' alt='icon' loading='lazy' />
                            </div>
                        </li>
                        <li
                            className={
                                `flex items-center text-sm font-normal mb-1 py-[13px] px-3 
                    rounded-lg custom-li side-menu 
                    ${path.pathname.includes('/setup') ?
            'bg-[#FFFFFF] text-[#29275F]' :
            'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                            onClick={() => {
                                dispatch(handleSidebarAnimation(false));
                                path.pathname.includes('/setup') ? null
                                    : navigate('/setup')
                            }}
                        >
                            {!path.pathname.includes('/setup') ?
                                <motion.img {...animationProps} src='/Images/setup-icon.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                <motion.img {...animationProps} src='/Images/setup-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                            <motion.img {...animationProps} src='/Images/setup-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                            Setup
                        </li>
                        <li
                            className={
                                `flex items-center text-sm font-normal mb-1 py-[13px] px-3 
                    rounded-lg custom-li side-menu 
                    ${path.pathname.includes('/user') ?
            'bg-[#FFFFFF] text-[#29275F]' :
            'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                            onClick={() => {
                                path.pathname.includes('/user') ? null
                                    : dispatch(handlePagination(paginationData)), dispatch(handleCurrentPage(1)), navigate('/user-management/users'), dispatch(handleSidebarAnimation(false));
                            }}
                        >
                            {!path.pathname.includes('/user') ?
                                <motion.img {...animationProps} src='/Images/users-icon.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                <motion.img {...animationProps} src='/Images/users-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                            <motion.img {...animationProps} src='/Images/users-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                            User Management
                        </li>
                        <li
                            className={
                                `flex items-center text-sm font-normal mb-1 py-[13px] px-3  
                    rounded-lg custom-li side-menu 
                    ${path.pathname.includes('/organisational') || path.pathname.includes('/account-types') ?
            'bg-[#FFFFFF] text-[#29275F]' :
            'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                        // onClick={() => {
                        //     path.pathname.includes('/organisational') || path.pathname.includes('/account-types') ? null
                        //         : navigate('/organisational')
                        // }}
                        >
                            {!path.pathname.includes('/organisational') && !path.pathname.includes('/account-types') ?
                                <motion.img {...animationProps} src='/Images/org-inactive.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                <motion.img {...animationProps} src='/Images/organisational-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                            <motion.img {...animationProps} src='/Images/organisational-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                            Organisational
                        </li>
                        <li
                            className={`flex items-center text-sm font-normal mb-1 py-[13px] px-3  
                    rounded-lg custom-li side-menu
                    ${path.pathname.includes('/employee-master') ? 'bg-[#FFFFFF] text-[#29275F]' : 'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                        // onClick={() => { path.pathname.includes('/employee-master') ? null : navigate('/employee-master') }}
                        >
                            {!path.pathname.includes('/employee-master') ?
                                <motion.img {...animationProps} src='/Images/Employee-master-icon.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                <motion.img {...animationProps} src='/Images/emp-master-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                            <motion.img {...animationProps} src='/Images/emp-master-active.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                            Employee Master
                        </li>
                        <li
                            className={
                                `flex items-center text-sm font-normal mb-1 py-[13px] px-3  
                    rounded-lg custom-li side-menu 
                    ${path.pathname.includes('/transaction-code') ?
            'bg-[#FFFFFF] text-[#29275F]' :
            'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                        // onClick={() => {dispatch(handleSidebarAnimation(false));
                        //     path.pathname.includes('/transaction-code') ? null
                        //         : navigate('/transaction-code')
                        // }}
                        >
                            {!path.pathname.includes('/transaction-code') ?
                                <motion.img {...animationProps} src='/Images/transaction-code.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                <motion.img {...animationProps} src='/Images/transaction-code.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                            <motion.img {...animationProps} src='/Images/transaction-code.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                            Transaction Codes
                        </li>
                        <li
                            className={
                                `flex items-center text-sm font-normal mb-1 py-[13px] px-3  
                    rounded-lg custom-li side-menu 
                    ${path.pathname.includes('/reports') ?
            'bg-[#FFFFFF] text-[#29275F]' :
            'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                        // onClick={() => {dispatch(handleSidebarAnimation(false));
                        //     path.pathname.includes('/reports') ? null
                        //         : navigate('/reports')
                        // }}
                        >
                            {!path.pathname.includes('/reports') ?
                                <motion.img {...animationProps} src='/Images/reports.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                <motion.img {...animationProps} src='/Images/reports.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                            <motion.img {...animationProps} src='/Images/reports.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                            Reports
                        </li>
                        <li
                            className={
                                `flex items-center text-sm font-normal mb-1 py-[13px] px-3  
                    rounded-lg custom-li side-menu 
                    ${path.pathname.includes('/calculation-routine') ?
            'bg-[#FFFFFF] text-[#29275F]' :
            'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                        // onClick={() => {dispatch(handleSidebarAnimation(false));
                        //     path.pathname.includes('/calculation-routine') ? null
                        //         : navigate('/calculation-routines')
                        // }}
                        >
                            {!path.pathname.includes('/calculation-routine') ?
                                <motion.img {...animationProps} src='/Images/calculation-routine.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                <motion.img {...animationProps} src='/Images/calculation-routine.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                            <motion.img {...animationProps} src='/Images/calculation-routine.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                            Calculation Routine
                        </li>
                    </ul>
                    <ul>
                        <li
                            className={`flex items-center text-sm font-normal mb-1 py-2 px-3  
                    rounded-lg custom-li side-menu 
                    ${path.pathname == '/notifications' ? 'bg-[#FFFFFF] text-[#29275F]' : 'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                        // onClick={() => navigate('/notifications')}
                        >
                            {path.pathname !== '/notifications' ?
                                <motion.img {...animationProps} src='/Images/notifications.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                <motion.img {...animationProps} src='/Images/notifications.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                            <motion.img {...animationProps} src='/Images/notifications.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                            Notifications
                        </li>
                        <li
                            className={`flex items-center text-sm font-normal mb-1 py-2 px-3  
                    rounded-lg custom-li side-menu 
                    ${path.pathname == '/payroll-settings' ? 'bg-[#FFFFFF] text-[#29275F]' : 'text-[#EEEDF8] hover:bg-[#4B42A3] cursor-pointer'}`}
                        // onClick={() => navigate('/payroll-settings')}
                        >
                            {path.pathname !== '/payroll-settings' ?
                                <motion.img {...animationProps} src='/Images/payroll-setting.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' /> :
                                <motion.img {...animationProps} src='/Images/payroll-setting.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />}
                            <motion.img {...animationProps} src='/Images/payroll-setting.svg' alt='icon' loading="lazy" decoding="async" className='pr-2' />
                            Payroll Settings
                        </li>
                    </ul>
                </nav>
            </div>
        </AnimatePresence>
    );
}

/* eslint-disable max-len */
import axios from 'axios';
import { authHeader } from '../Redux/helper'
import { Header } from '../Redux/helper';

async function getEnvs() {
    try {
        const headers = await Header();
        const data = await axios.get(` https://${headers.host}/api/user-management/get-tenant-configuration`, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response };
    }
}

// country related functions
async function CountriesListing(endpoint){
    const headers = await authHeader();
    try {
        const data = await axios.get(`https://${headers.host}/api/payroll-management/countries`, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}

async function sendPasswordResetLink(email) {
    try {
        const headers = await Header();
        const data = await axios.post(`https://${headers.host}/api/user-management/forgot-password-send-link/${email}`, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response };
    }
}


async function VerifyToken(body) {
    try {
        const headers = await Header();
        const data = await axios.post(`https://${headers.host}/api/user-management/verify-token`, body, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}

async function ForgotPassword(body) {
    try {
        const headers = await Header();
        const data = await axios.patch(`https://${headers.host}/api/user-management/forgot-password-verify`, body, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response };
    }
}

async function ResetPassword(body) {
    try {
        const headers = await Header();
        const data = await axios.patch(`https://${headers.host}/api/user-management/forgot-password-verify`, body, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response };
    }
}


async function UpdatePassword(body) {
    const headers = await authHeader();
    try {
        const data = await axios.patch(`https://${headers.host}/api/user-management/update-password`, body, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}

async function AddUserGroup(body) {
    const headers = await authHeader();
    try {
        const data = await axios.post(`https://${headers.host}/api/user-management/user-group`, body, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}

async function getUserGroup(endpoint) {
    const headers = await authHeader();
    try {
        const data = await axios.get(`https://${headers.host}/api/user-management/user-group${endpoint}`, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}
async function UpdateUserGroup(id, body) {
    const headers = await authHeader();
    try {
        const data = await axios.patch(`https://${headers.host}/api/user-management/user-group/${id}`, body, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}
async function ViewUserGroup(id) {
    const headers = await authHeader();
    try {
        const data = await axios.get(`https://${headers.host}/api/user-management/user-group/${id}`, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}
async function CloneUserGroup(id, body) {
    const headers = await authHeader();
    try {
        const data = await axios.post(`https://${headers.host}/api/user-management/user-group/clone/${id}`, body, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}


async function deleteUserGroup(id) {
    const headers = await authHeader();
    try {
        const data = await axios.delete(`https://${headers.host}/api/user-management/user-group/${id}`, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response };
    }
}
async function AddUser(body) {
    const headers = await authHeader();
    try {
        const data = await axios.post(`https://${headers.host}/api/user-management/`, body, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}
async function ListUsers(endpoint) {
    const headers = await authHeader();
    try {
        const data = await axios.get(`https://${headers.host}/api/user-management/${endpoint}`, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}
async function ActivateDeactivateUser(id, body) {
    const headers = await authHeader();
    try {
        const data = await axios.patch(`https://${headers.host}/api/user-management/activate-deactivate/${id}`, body, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}

async function ListCurrencyTypes(endpoint) {
    const headers = await authHeader();
    try {
        const data = await axios.get(`https://${headers.host}/api/payroll-management/currency-types${endpoint}`, {
            headers: headers.headers,
        });
        return { error: false, data: data.data };
    } catch (error) {
        return { error: true, data: error.response.data };
    }
}


async function StaticDataForPayroll(endpoint) {
    try {
        const headers = await Header();
        const data = await axios.get(`https://${headers.host}/json_static_data/${endpoint}`)
        // const data = await axios.get(`https://abhishektest-s3-origin-issue.s3.eu-west-1.amazonaws.com/json_static_data/Screenshot+from+2024-09-24+22-48-02.png`)
        return { error: false, data: data }
    }
    catch (error) {
        return { error: true, data: error.response };
    }
}


async function ListTaxMethodsofCountry(countryId, queryParam = '') {
    const headers = await authHeader();
    try {
        const data = await axios.get(
            `https://${headers.host}/api/payroll-management/tax-methods?country_id=${countryId}${queryParam}`, // Add query param here
            { headers: headers.headers }
        );
        return { error: false, data: data.data };
    } catch (error) {
        return { error: true, data: error.response.data };
    }
}

// create payroll

async function CreatePayroll(body) {
    const headers = await authHeader();
    try {
        const data = await axios.post(`https://${headers.host}/api/payroll-management/`, body, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}

async function ListPayrolls(endpoint) {
    const headers = await authHeader();
    try {
        const data = await axios.get(`https://${headers.host}/api/payroll-management/${endpoint}`, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}

// https://dev-tenant.belinatest.com/api/payroll-management/?limit=10&search=teat&country_id=00854ab6-677d-4f60-80c6-78ea4f383051&payroll_status=active,inactive,creating&payroll_type=period,daily,hourly




async function patchAPI(endpoint, body) {
    const headers = await authHeader();
    try {
        const data = await axios.patch(`https://${headers.host}/api/${endpoint}`, body, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}

async function getAPI(endpoint) {
    const headers = await authHeader();
    try {
        const data = await axios.get(`https://${headers.host}/api/${endpoint}`, {
            headers: headers.headers,
        })
        return { error: false, data: data.data }
    }
    catch (error) {
        return { error: true, data: error.response.data };
    }
}



export const dataService = {
    sendPasswordResetLink,
    ForgotPassword,
    VerifyToken,
    UpdatePassword,
    getEnvs,
    AddUserGroup,
    getUserGroup,
    deleteUserGroup,
    UpdateUserGroup,
    ViewUserGroup,
    CloneUserGroup,
    AddUser,
    ListUsers,
    ActivateDeactivateUser,
    CountriesListing,
    StaticDataForPayroll,
    ListCurrencyTypes,
    CreatePayroll,
    ListTaxMethodsofCountry,
    ListPayrolls,
    
    patchAPI,
    getAPI
}
import React from 'react';
import { motion } from 'framer-motion';
import MenuBox from '../../CommonComponent/MenuBox';

export default function Setup() {
    // Define the container animation with a 2ms stagger
    const container = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3, 
            },
        },
    };

    // Define the individual item animation strictly from left to right
    const item = {
        hidden: { opacity: 0, x: -50, y: 0 }, // Ensure movement only on x-axis (left to right)
        visible: { opacity: 1, x: 0, y: 0, transition: { duration: 0.3 } }, // Animate to full opacity with a faster 0.3s duration
    };

    const menuItems = [
        {
            icon: <img src='/Images/time-period-icon.svg' alt='icon' loading='lazy' />,
            title: 'Periods',
            description: 'Stores the transactions, separately, for each period',
            path: '/setup/periods'
        },
        {
            icon: <img src='/Images/Units.svg' alt='icon' loading='lazy' />,
            title: 'Units',
            description: 'Processes transactions with unit values for any unit type',
            path: '/setup/units'
        },
        // {
        //     icon: <img src='/Images/transaction-code.svg' alt='icon' />,
        //     title: 'Transaction codes',
        //     description: 'Processes various payroll transactions for employees.',
        //     path: '/setup/transaction-codes'
        // },
        {
            icon: <img src='/Images/Currencies.svg' alt='icon' loading='lazy' />,
            title: 'Currencies',
            description: 'Setup the currencies & exchange rates for the payroll.',
            path: '/setup/currencies'
        },
        {
            icon: <img src='/Images/company.svg' alt='icon' loading='lazy' />,
            title: 'Company details',
            description: 'Organisation address for reports & bank details for automated payments.',
            path: '/setup/company'
        },
        {
            icon: <img src='/Images/third-party.svg' alt='icon' loading='lazy' />,
            title: 'Third parties',
            description: 'Setup bank & address details for third-party payments',
            path: '/setup/third-party'
        },
        {
            icon: <img src='/Images/other-leaves.svg' alt='icon' loading='lazy' />,
            title: 'Other leaves',
            description: 'Setup other leave types & the rules that apply to them.',
            path: '/setup/other-leaves'
        },
        {
            icon: <img src='/Images/Ranges.svg' alt='icon' loading='lazy' />,
            title: 'Ranges',
            description: 'Setup age & wage rate ranges for reporting',
            path: '/setup/ranges'
        }
    ];

    return (
        <div className='bg-white rounded-tl-lg h-[calc(100vh-51px)]'>
            {/* Container animation wraps the whole grid */}
            <motion.div
                initial="hidden" // Start with hidden state
                animate="visible" // Animate to visible state
                variants={container} // Use the container animation defined above
                className="px-6 pt-[72px]"
            >
                <h1 className="text-[#2F2E38] text-[32px] font-bold pb-[42px]">Setup menu</h1>
                <div className="grid grid-cols-4 gap-4">
                    {menuItems.map((itemData, index) => (
                        // Wrap each MenuBox in a motion.div for individual animation
                        <motion.div key={index} variants={item}>
                            <MenuBox
                                icon={itemData.icon}
                                title={itemData.title}
                                description={itemData.description}
                                path={itemData.path}
                            />
                        </motion.div>
                    ))}
                </div>
            </motion.div>
        </div>
    );
}

import React from 'react'
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function HoveringTooltip({place, Name, id}) {
    return (
        <ReactTooltip
            id={id}
            place={place || "left"}
            effect="solid"
            backgroundColor="#5E5C70"
            textColor="#F1F1F4"
            
            noArrow
            style={{
                backgroundColor: "#5E5C70",
                color: "#F1F1F4",
                height: '26px',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '2px',
            }}
            className='tooltip-class'
        >
            {Name}
        </ReactTooltip>
    )
}
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import CardHeder from '../../CommonComponent/CardHeder'; // Fixed typo
import StatusBadge from '../../CommonComponent/StatusBadge';
import ViewItemMapping from '../../CommonComponent/ViewItemMapping';
import { dataService } from '../../services/dataService';
import moment from 'moment';
import UpdateProfile from './UpdateProfile';
import { functions, items, organizationalItems } from '../../CommonComponent/staticValues';
import { useSelector } from 'react-redux';

export default function Profile() {
    const [loading, setLoading] = useState(true);
    const userData = useSelector((state) => state.auth);
    
    const [viewData, setViewData] = useState({
        userInfo: {
            Role: '',
            'Organisation name': '',
            'Last logged in': ''
        }
    });

    const [isUpdate, setIsUpdate] = useState(false);

    const handleFetch = async () => {
        const data = await dataService.getAPI(`user-management/user-profile`);
        let userPrivilege = {};
        let employeeMaster = '';
        const payroll_functions = data?.data?.data?.user_privilege?.payroll_functions; // Assuming payroll_functions is part of the response
    
        if (Array.isArray(data?.data?.data?.user_privilege)) {
            const res = await dataService.getUserGroup('?limit=30');
            const groupIds = data?.data?.data?.user_privilege;
            userPrivilege = groupIds.map((id) => {
                const group = res.data.data.find((g) => g.group_id === id);
                return group ? group.group_name : null;
            });
        } else if (data?.data?.data?.user_privilege) {
            let menuObj = {}; // Initialize menuObj here
    
            const menuItems = Object.values(data?.data?.data?.user_privilege.menu_items);
    
            menuItems.forEach((element) => {
                if (typeof element === 'string' && element.includes(':')) {
                    const [key, value] = element.split(':');
                    const formattedKey = key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.slice(1);
                    menuObj[formattedKey] = menuObj[formattedKey] || [];
                    menuObj[formattedKey].push(value);
                } else if (element === 'employee_master') {
                    employeeMaster = 'Employee Master';
                }
            });
    
            // Filter Setup Items
            if (Array.isArray(menuObj.Setup)) {
                menuObj.Setup = items
                    .filter(item => menuObj.Setup.includes(item.id))
                    .map(item => item.label); // Map to correct labels
            }
    
            // Filter Organisational Items
            if (Array.isArray(menuObj.Organisational)) {
                menuObj.Organisational = organizationalItems
                    .filter(item => menuObj.Organisational.includes(item.id))
                    .map(item => item.label); // Map to correct labels
            }
    
            let payrollObj = [];
            console.log(payroll_functions ,"payroll_functions")
            if (Array.isArray(payroll_functions)) {
                payrollObj = functions
                    .filter(item => payroll_functions?.includes(item.id)) // Added null/undefined check for includes
                    .map(item => item.label); // Map payroll functions to correct labels
            }
    
    
            // Combine the result
            userPrivilege = {
                Menu: menuObj,
                null:employeeMaster ? {"Employee Master ": null} : null,
                Functions: payrollObj.length ? payrollObj : null,
            };
        }
    
        // Update the state with fetched data
        setViewData(prevState => ({
            ...prevState,
            ...data?.data?.data,
            user_privilege: userPrivilege,
            userInfo: {
                Role: data?.data?.data?.user_role === 'admin' ? 'Administrator' :
                    data?.data?.data?.user_role === 'super-admin' ? 'Super Admin' : 'User',
                'Organisation name': data?.data?.data?.organisation_name,
                'Last logged in': data?.data?.data?.last_logged_in 
                    ? moment.unix(data?.data?.data?.last_logged_in).format('YYYY-MM-DD HH:mm') 
                    : '-'
            }
        }));
    
        setLoading(false);
    };
    

    useEffect(() => {
        handleFetch();
    }, [loading]);

    console.log(userData, "userData");

    return (
        <CardHeder headerName="View Profile">
            <div className="mt-4">
                <div className="flex justify-between items-center mb-6">
                    <div className="flex justify-start items-center">
                        {/* Profile Icon */}
                        <div className="text-[#29275F] text-[28px] font-normal bg-[#EEEDF8] min-w-[70px] w-[70px] h-[70px] rounded-full" 
                            data-testid="profile-btn">
                            <button 
                                type="button" 
                                className="flex items-center justify-center w-full h-full">
                                {loading ? (
                                    <div className="bg-[#dbd9d9f3] w-[70px] h-[70px] animate-pulse rounded-full"></div>
                                ) : (
                                    <>
                                        {viewData?.first_name?.[0]?.toUpperCase()}{viewData?.last_name?.[0]?.toUpperCase() ?? ''}
                                    </>
                                )}
                            </button>
                        </div>

                        {/* Profile Information */}
                        <div className="px-4">
                            <div className="flex">
                                {loading ? (
                                    <div className="bg-[#dbd9d9f3] h-[25px] w-[150px] animate-pulse mb-2"></div>
                                ) : (
                                    <p className="text-[#2F2E38] text-[18px] font-semibold mr-4" data-test-id="name">
                                        {viewData?.first_name} {viewData?.last_name}
                                    </p>
                                )}

                                {!loading && <StatusBadge status={viewData?.user_status} />}
                            </div>
                            {loading ? (
                                <div className="bg-[#dbd9d9f3] h-[18px] w-[250px] animate-pulse"></div>
                            ) : (
                                <p className="mt-2 text-[#2F2E38] font-normal text-[16px]" 
                                    data-test-id="email">{viewData?.email_address}</p>
                            )}
                        </div>
                    </div>

                    {/* Update Button */}
                    <button
                        className={`${loading ? 'opacity-20' : ''} text-[#29275F] border-[#29275F] 
                        border-2 rounded-lg flex items-center px-2.5 min-w-max`}
                        onClick={() => setIsUpdate(true)}
                        data-test-id="update-profile"
                        disabled={loading}
                    >
                        <img src="/Images/update_icon.svg" className="pl-2.5" alt="Update Icon" loading="lazy" />
                        <span className="p-2.5 text-[16px] font-bold">Update</span>
                    </button>
                </div>

                {/* ViewItemMapping for userInfo */}
                <div className="overflow-y-auto h-[calc(100vh-300px)] customScrollbar">
                    <ViewItemMapping obj={viewData?.userInfo} loading={loading} mainClassName="flex" />
                    {/* Features Section */}
                    {(!loading && Object.keys(viewData?.user_privilege).length !== 0) && (
                        <div className="mt-6">
                            <p className="text-[#2F2E38] text-[18px] font-semibold mr-4 mb-4">
                                {Array.isArray(viewData?.user_privilege) ? 'User Groups' : 'Features'}
                            </p>
                            <ViewItemMapping obj={viewData?.user_privilege} loading={loading} />
                        </div>
                    )}
                </div>
            </div>
            <UpdateProfile isUpdate={isUpdate} setIsUpdate={setIsUpdate} viewData={viewData} setViewLoading={setLoading} />
        </CardHeder>
    );
}
